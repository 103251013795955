@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
    --font-base: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
    ;

    --primary-color: #F1F7B5;
    --secondary-color: #0d2c52;
    --black-color: #062953;
    --lightGray-color: #e4e4e4;
    --gray-color: #425a82;
    --brown-color: #46364a;
    --white-color: #ffffff;
    --pink-color: #fd8a8a;
    --blue-color: #797ed0;
    --green-color: #A8D1D1;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}