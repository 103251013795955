.app__credits-div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
  padding-bottom: 30px;

  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: var(--blue-color);
    &:hover,
    &:focus,
    &:active {
      color: var(--blue-color);
      outline: 0;
      &:after {
        width: 100%;
      }
      & > * {
        color: var(--blue-color) !important;
        transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      position: relative;
      bottom: 0.1em;
      background-color: var(--blue-color);
      transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0.5;
    }
    color: var(--gray-color);
    flex-direction: column;

    transition: all 0.4s ease-in-out;
  }
}
