.app__skills {
  flex: 1;
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  flex-direction: column;
}

.app__skills-container {
  width: 80%;
  margin: auto;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 800px;

  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease-in-out;

  div {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    border: 2px solid var(--black-color);
    background-color: rgba(251, 251, 251, 0.864);

    img {
      width: 60%;
    }

    &:hover {
      box-shadow: 0 0 25px #c9cb91;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    color: var(--black-color);
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}
