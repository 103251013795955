.app__experiences {
  flex: 1;
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  flex-direction: column;
}

.app__experiences-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 450px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (max-width: 450px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.app__experiences-timeline {
  margin: 2em auto;
  position: relative;
  max-width: 46em;

  &:before {
    background-color: var(--black-color);
    content: "";
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
  }
}

.app__experiences-timeline-event {
  position: relative;
  list-style: none;

  &:hover {
    .app__experiences-timeline-event-icon {
      transform: rotate(-45deg);
      background-color: var(--pink-color);
    }

    .app__experiences-timeline-event-thumbnail {
      box-shadow: inset 40em 0 0 0 var(--pink-color);
    }
  }
}

.app__experiences-timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
  color: var(--black-color);

  h3 {
    font-size: 1.75em;
    @media screen and (max-width: 450px) {
      font-size: 1.5em;
    }
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 1.2em;
    @media screen and (max-width: 450px) {
      font-size: 1em;
    }
  }

  p {
    white-space: pre-wrap;
    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.app__experiences-timeline-event-icon {
  transform: rotate(45deg);

  background-color: var(--black-color);
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
  transition: transform 0.2s ease-in;
}

.app__experiences-timeline-event-thumbnail {
  color: white;
  font-size: 0.75em;

  background-color: var(--black-color);
  box-shadow: inset 0 0 0 0em var(--pink-color);
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
  transition: box-shadow 0.5s ease-in 0.1s;
}
