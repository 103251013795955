.app__footer {
  flex: 1;
  display: flex;
  width: 100%;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app_footer-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    margin-top: 10px;
  }

  p {
    font-size: 20px;
    max-width: 600px;
    color: var(--black-color);
    line-height: 1.3;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;

    @media screen and (max-width: 450px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .app_footer-button-wrapper {
    margin: 20px;
  }
}
