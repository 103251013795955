#container {
  position: absolute;
  text-align: center;
  height: 118px;

  @media screen and (max-width: 1024px) {
    height: 98px;
  }
  @media screen and (max-width: 768px) {
    height: 82px;
  }
  @media screen and (max-width: 450px) {
    height: 62px;
  }
}

#text {
  display: inline-block;
}

#cursor {
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 70px;
  background-color: var(--pink-color);
  animation: blink 0.5s step-end infinite;
  @media screen and (max-width: 1024px) {
    height: 50px;
  }
  @media screen and (max-width: 768px) {
    height: 40px;
  }
  @media screen and (max-width: 450px) {
    height: 25px;
  }
}

@keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: var(--pink-color);
  }
  100% {
    background-color: var(--pink-color);
  }
}
