#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__hero {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__hero-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__hero-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 450px) {
    padding-left: 0px;
    padding-right: 30px;
  }
}

.app__hero-button-group {
  display: flex;
  gap: 30px;
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 30px;
    gap: 10px;
    width: 100%;
    a {
      width: 100%;
    }
  }
}

.app__hero-text-container {
  display: flex;
  flex-direction: column;
}

.app__hero-head-text {
  font-size: 100px;
  font-weight: 800;
  width: 100%;
  color: var(--black-color);
  text-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1),
    10px 20px 5px rgba(0, 0, 0, 0.05), -10px 20px 5px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 1024px) {
    font-size: 80px;
  }
  @media screen and (max-width: 768px) {
    font-size: 70px;
  }
  @media screen and (max-width: 450px) {
    font-size: 40px;
  }
}

.app__hero-subhead-text {
  font-size: 70px;
  font-weight: 800;
  display: flex;
  color: var(--black-color);
  opacity: 0.6;
  height: 118px;

  @media screen and (max-width: 1024px) {
    height: 98px;
    font-size: 50px;
  }
  @media screen and (max-width: 768px) {
    height: 82px;
    font-size: 40px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 10px;
    height: 62px;
    font-size: 25px;
  }
}

.app__hero-head-text-small {
  color: var(--secondary-color);
  font-size: 26px;
  font-size: 1rem;
  font-weight: 500;
  -webkit-text-stroke: 1px;
  width: 800px;
  letter-spacing: 1px;
  line-height: 1.5;
  opacity: 0.8;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serifz;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 1.3;
  }
}

.app__hero-social-media {
  display: none;
  @media screen and (max-width: 450px) {
    display: flex;
  }
}

.app_hero-desc-link {
  margin-left: 4px;
  margin-right: 4px;
  color: var(--blue-color);
  text-decoration: none;
}